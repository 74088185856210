import { useState, useEffect } from 'react';
import _axios from 'axios';
import { getUserLocalStorage, refreshAndSaveToken } from './GoogleAuth';
import { makeUseAxios } from 'axios-hooks';
import LoadingBar from 'react-top-loading-bar'; // https://github.com/klendi/react-top-loading-bar
import React from 'react';

export const axios = _axios.create({
    timeout: 30000
}); // export this and use it in all your components

export const useAxiosEx = makeUseAxios({
    axios
});

export function useAxiosLoader(): boolean {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const inc = (mod: number): void => {
            setCounter((c) => c + mod);
        };

        const handleRequest = (config: any): any => {
            inc(1);
            config.headers.Authorization = `Bearer ${localStorage.getItem('jwt') ?? ''}`;
            return config;
        };
        const handleResponse = (response: any): any => {
            inc(-1);

            // if this is the last request, chcek for our token expiration
            if (counter === 0) {
                const [_userToken, expirationInMinutes] = getUserLocalStorage();
                if (expirationInMinutes < 30) {
                    void refreshAndSaveToken();
                }
            }

            return response;
        };
        const handleError = (error: any): any => {
            inc(-1);
            return Promise.reject(error);
        };

        // add interceptors
        const reqInterceptor = axios.interceptors.request.use(handleRequest, handleError);
        const resInterceptor = axios.interceptors.response.use(handleResponse, handleError);

        return () => {
            // remove all intercepts when done
            axios.interceptors.request.eject(reqInterceptor);
            axios.interceptors.response.eject(resInterceptor);
        };
    }, []);

    return counter > 0;
}

/*
const dataFetchReducer = (state: any, action: any): any => {
    switch (action.type) {
        case 'FETCH_CLEAR':
            return { ...state, isLoaded: false, isLoading: false, isError: false, data: action.payload };
        case 'FETCH_INIT':
            return { ...state, isLoaded: false, isLoading: true, isError: false };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isError: false,
                data: action.payload
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                isError: true
            };
        default:
            throw new Error();
    }
};

function _useDataApi(initialUrl: string, initialData: any, initialConfig: AxiosRequestConfig<any> | undefined = undefined): any {
    const [url, setUrl] = useState(initialUrl);
    const [config, setConfig] = useState(initialConfig);
    const [refresh, setRefresh] = useState(true);

    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        data: initialData
    });

    function setClear(data: any = undefined): void {
        dispatch({ type: 'FETCH_CLEAR', payload: data });
    }

    useEffect(() => {
        let didCancel = false;

        const fetchData = async (): Promise<void> => {
            dispatch({ type: 'FETCH_INIT' });

            try {
                const result = await axios(url, config);
                setRefresh(false);

                if (!didCancel) {
                    dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: 'FETCH_FAILURE' });
                }
            }
        };

        if (url === '') {
            dispatch({ type: 'FETCH_SUCCESS', payload: initialData });
        }

        if (refresh && url !== '') {
            void fetchData();
        }

        return () => {
            didCancel = true;
        };
    }, [url, refresh, config]);

    return [state, setUrl, setRefresh, setConfig, setClear];
}
*/

export function TopLoadingBar() {
    // loading bar component ref
    const ref = React.useRef<any>(null);

    React.useEffect(() => {
        // Add a request interceptor
        axios.interceptors.request.use(
            (config) => {
                if (ref?.current) ref.current.continuousStart(20, 500);
                return config;
            },
            (error) => Promise.reject(error)
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            (response) => {
                if (ref?.current) ref.current.complete();
                return response;
            },
            (error) => {
                if (ref?.current) ref.current.complete();
                return Promise.reject(error);
            }
        );
    }, []);

    return <LoadingBar shadow color="#F05028" ref={ref} />;
}
