import { ReactElement } from 'react';
import { Typeahead as SourceTypeahead } from 'react-bootstrap-typeahead';
import { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { RenderMenuItemChildren, TypeaheadMenuProps } from 'react-bootstrap-typeahead/types/components/TypeaheadMenu';
import { FilterByCallback, LabelKey, RenderToken, RenderTokenProps, TypeaheadPropsAndState } from 'react-bootstrap-typeahead/types/types';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

export type TypedTypeaheadProps<T extends Option> = Omit<
    TypeaheadComponentProps,
    'filterBy' | 'renderMenuItemChildren' | 'renderToken' | 'labelKey' | 'onChange' | 'options'
> & {
    filterBy?: string[] | TypedFilterByCallback<T>;
    renderMenuItemChildren?: TypedRenderMenuItemChildren<T>;
    renderToken?: TypedRenderToken<T>;
    labelKey?: TypedLabelKey<T>;
    onChange?: TypedOnChange<T>;
    options: T[];
};

export const TypedTypeahead = <T extends Option = never>(props: TypedTypeaheadProps<T>): ReactElement => {
    return (
        <SourceTypeahead
            {...props}
            filterBy={props.filterBy as string[] | FilterByCallback}
            renderMenuItemChildren={props.renderMenuItemChildren as RenderMenuItemChildren}
            renderToken={props.renderToken as RenderToken}
            labelKey={props.labelKey as LabelKey}
            onChange={props.onChange as (selected: Option[]) => void}
        />
    );
};

// Importing from Typeahead messed up TS
export type Option = string | Record<string, any>;
// The following wrappers are added to support generics in our callbacks. We're casting back to original later on
export type TypedLabelKey<T extends Option> = T extends object ? (string & keyof T) | ((option: T) => string) : never;
type TypedOnChange<T> = (selected: T[]) => void;
type TypedRenderToken<T extends Option> = (option: T, props: RenderTokenProps, idx: number) => JSX.Element;
type TypedFilterByCallback<T extends Option> = (option: T, state: TypeaheadPropsAndState) => boolean;
type TypedRenderMenuItemChildren<T extends Option> = (option: T, menuProps: TypeaheadMenuProps, idx: number) => JSX.Element;
