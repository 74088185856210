import React from 'react';
import { createRoot } from 'react-dom/client';
import { Tabulator } from 'react-tabulator';
import { flushSync } from 'react-dom';

export const ObjectByString = function (o: any, s: any): any {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
            if (!o) return;
        } else {
            return;
        }
    }
    return o;
};

export async function copyToClipboard(textToCopy: string): Promise<void> {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        await navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        await new Promise((res, rej) => {
            // here the magic happens
            // eslint-disable-next-line prefer-promise-reject-errors
            document.execCommand('copy') ? res(null) : rej();
            textArea.remove();
        });
    }
}

export function timeSince(when: Date): any {
    // this ignores months
    const date_today = new Date();
    const obj: any = {};
    obj._milliseconds = date_today.getTime() - when.getTime();
    obj.milliseconds = obj._milliseconds % 1000;
    obj._seconds = (obj._milliseconds - obj.milliseconds) / 1000;
    obj.seconds = obj._seconds % 60;
    obj._minutes = (obj._seconds - obj.seconds) / 60;
    obj.minutes = obj._minutes % 60;
    obj._hours = (obj._minutes - obj.minutes) / 60;
    obj.hours = obj._hours % 24;
    obj._days = (obj._hours - obj.hours) / 24;
    obj.days = obj._days % 365;
    obj.years = (obj._days - obj.days) / 365;
    return obj;
}

export function GetCopyToClipboardSpan(text: string): string {
    return `<div class="link-primary" style="float:right;margin-right: 4px;" onclick="navigator.clipboard.writeText('${text}');"><i class="bi bi-clipboard"></i></div>`;
}
/*
export function reactFormatterEx(JSX: JSX.Element) {
    return function customFormatter(cell: Tabulator.CellComponent, formatterParams: any, onRendered: (callback: () => void) => void) {
        const renderFn = () => {
            const cellEl = cell.getElement();
            const root = createRoot(cellEl!);
            root.render(<React.StrictMode>{JSX}</React.StrictMode>);
        };

        onRendered(renderFn); // initial render only.
        return null;
    };
}
*/

export function reactFormatterEx(JSX: any) {
    return function customFormatter(cell: Tabulator.CellComponent, formatterParams: any, onRendered: (callback: () => void) => void) {
        // cell - the cell component
        // formatterParams - parameters set for the column
        // onRendered - function to call when the formatter has been rendered
        const renderFn = () => {
            const cellEl = cell.getElement();
            if (cellEl != null) {
                const formatterCell = cellEl.querySelector('.formatterCell');
                if (formatterCell != null) {
                    const root = createRoot(formatterCell); // createRoot(container!) if you use TypeScript
                    root.render(<React.StrictMode>{JSX}</React.StrictMode>);
                }
            }
        };

        onRendered(renderFn); // initial render only.
        return '<div class="formatterCell"></div>';
    };
}

export function reactFormatterEx2(JSX: JSX.Element) {
    return function customFormatter(cell: any, formatterParams: any, onRendered: (callback: () => void) => void) {
        // cell - the cell component
        // formatterParams - parameters set for the column
        // onRendered - function to call when the formatter has been rendered
        const renderFn = () => {
            const cellEl = cell.getElement();
            if (cellEl) {
                const formatterCell = cellEl.querySelector('.formatterCell');
                if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(JSX, { cell });

                    // Create a root for the formatterCell if it doesn't already have one
                    let root = formatterCell._reactRoot;
                    if (!root) {
                        root = createRoot(formatterCell);
                        formatterCell._reactRoot = root; // Store the root for potential future re-renders
                    }

                    // Render the component
                    root.render(CompWithMoreProps);
                }
            }
        };

        onRendered(renderFn); // initial render only.
        setTimeout(() => {
            renderFn(); // render every time cell value changed.
        }, 0);
        return '<div class="formatterCell"></div>';
    };
}

export function reactFormatterEx3(JSX: JSX.Element, defaultProps = {}) {
    return function customFormatter(cell: any, formatterParams: any, onRendered: (callback: () => void) => void) {
        const cellValue = cell.getValue();
        const cellElement = cell.getElement();

        // Clear previous content
        cellElement.innerHTML = '';

        // Merge default props with any additional props passed via formatterParams
        const props = {
            cellData: cellValue,
            ...defaultProps,
            ...(formatterParams || {})
        };

        // Create React root and render the component
        const root = createRoot(cellElement);
        const CompWithMoreProps = React.cloneElement(JSX, { cell, ...props });
        root.render(CompWithMoreProps);

        // Attach cleanup to unmount React when necessary
        cell.formatterCleanup = () => {
            root.unmount();
        };

        return cellElement;
    };
}
