import { type JiraBrightpickProject } from '@bp/utils';
import * as formatters from './TabulatorEx-formatters';
import * as helpers from './TabulatorEx-helpers';
import * as jiraHelpers from './jira-helpers';
import TabulatorTables from 'react-tabulator\\lib\\types\\TabulatorTypes.d';
import ReactDOM from 'react-dom/client';
import { ProjectCardFooter } from '../Components/Project/ProjectCardFooter';
import React from 'react';
import { projectCodeNormalizedToOriginal } from '../Utils/utils';
import { ProjectSemaphoreChip } from '../Components/Project/ProjectSemaphore';
import { IntuitionLogicStateChip } from '../Components/Project/IntuitionLogicStateChip';
import { ProjectSemaphore } from '@bp/utils/build/types/JIRA/jira-project';
import { Avatar } from '../Components/Common/AvatarGoogle';
import { reactFormatterEx } from './utils';
import { ProjectPerformanceSparkline } from '../Components/Charts/Charts-Sparklines/ProjectPerformanceSparkline';

const percentFormatterParamsPositive = {
    legend: (value: string): string => value + ' %',
    color: (value: number): 'red' | 'yellow' | 'orange' | 'lightgreen' | undefined => {
        if (value < 25) return 'red';
        if (value < 50) return 'yellow';
        if (value < 75) return 'yellow';
        if (value < 99) return 'orange';
        if (value >= 100) return 'lightgreen';
    }
};

export const getColumn = (fieldName: string, overrides?: Partial<TabulatorTables.Tabulator.ColumnDefinition>): object =>
    Object.assign(
        {},
        tabulatorColumnMap.find((column) => column.field === fieldName),
        {
            vertAlign: 'middle',
            ...overrides
        }
    );

export const tabulatorColumnMap = [
    {
        field: '#',
        title: '#',
        formatter: 'rownum',
        width: 35,
        maxWidth: 50,
        hozAlign: 'center',
        vertAlign: 'middle',
        headerSort: true
    },
    {
        field: '~',
        title: '',
        formatter: 'handle',
        width: 35,
        headerSort: false
    },
    {
        field: '$',
        title: '',
        width: 35,
        formatter: 'rowSelection',
        titleFormatter: 'rowSelection',
        hozAlign: 'center',
        vertAlign: 'middle',
        headerSort: false,
        cellClick: function (e: any, cell: any) {
            cell.getRow().toggleSelect();
        }
    },
    {
        field: 'DocVisOrder',
        title: '#',
        width: 35,
        hozAlign: 'center'
    },
    {
        field: 'ItemName',
        title: 'Item Name',
        // width: '',
        headerFilter: 'input',
        vertAlign: 'middle',

        formatter: formatters.tabulatorFormatterItemName
    },
    {
        field: 'ItemCode',
        title: 'Item Code',
        headerFilter: 'input',
        vertAlign: 'middle',

        width: 200,
        formatter: formatters.tabulatorFormatterItemName
    },
    {
        field: 'Comments',
        title: 'UserText',
        headerFilter: 'input',
        width: 100
    },
    {
        field: 'Filament',
        title: 'Filament',
        headerFilter: 'input',
        hozAlign: 'center',
        width: 20,
        formatter: formatters.tabulatorFormatterFilament
    },
    {
        field: 'Depth',
        title: 'Depth',
        headerFilter: 'input',
        hozAlign: 'center',
        width: 20
    },
    {
        field: 'ItemPhoto',
        title: 'Photo',
        width: 70,
        formatter: 'image',
        hozAlign: 'center',
        formatterParams: {
            urlPrefix: `${process.env.PUBLIC_URL}/SAPImages/`,
            urlSuffix: '.jpg',
            height: '22px',
            width: 'auto'
        },
        mutator: function (_value: any, data: any) {
            return data.ItemCode;
        },
        tooltip: function (e: any, cell: any, _onRendered: any): any {
            const value = cell.getValue();
            const el = document.createElement('div');
            el.innerHTML = `<div style="width:300px; height:300px"><img style="max-width: 100%;max-height: 100%;"src="/SAPImages/${
                value as string
            }.jpg"></img><div>`;
            return el;
        }
    },
    {
        field: 'ItmsGrpNam',
        title: 'Items Group',
        hozAlign: 'center',
        sorter: 'string',
        width: 120,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'ItmsGrpNam' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'ItmsGrpNam' }
    },
    {
        field: 'MachiningTypeDesc',
        title: 'Machining Type',
        hozAlign: 'center',
        sorter: 'string',
        width: 120,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'MachiningTypeDesc' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'MachiningTypeDesc' }
    },
    {
        field: 'CardName',
        title: 'Card Name',
        width: 200,
        headerFilter: 'input'
    },
    {
        field: 'CardCode',
        title: 'Card Code',
        width: 100,
        hozAlign: 'center',
        headerFilter: 'input'
    },
    {
        field: 'WhsCode',
        title: 'Whs Code',
        width: 350,
        hozAlign: 'left',
        headerFilter: 'input'
    },
    {
        field: 'BinCode',
        title: 'Bin Code',
        width: 350,
        hozAlign: 'left',
        headerFilter: 'input'
    },
    {
        field: 'DocStatus',
        title: 'Status',
        hozAlign: 'center',
        width: 80,
        formatter: formatters.tabulatorFormatterDocStatus,
        headerFilter: 'input'
    },
    {
        field: 'DocStatusProductionOrder',
        title: 'Status',
        hozAlign: 'center',
        width: 100,
        formatter: formatters.tabulatorFormatterDocStatusProductionOrder,
        headerFilter: 'input'
    },
    {
        field: 'ApprovalRequestStatus',
        title: 'Approval Status',
        hozAlign: 'center',
        width: 100,
        formatter: formatters.tabulatorFormatterApprovalStatus
    },
    {
        field: 'StageStatus',
        title: 'Stage Status',
        hozAlign: 'center',
        width: 100,
        formatter: formatters.tabulatorFormatterApprovalStatus,
        headerFilter: 'input'
    },
    {
        field: 'DocType',
        title: 'Doc Type',
        hozAlign: 'left',
        width: 120,
        formatter: formatters.tabulatorFormatterDocType,
        headerFilter: 'input'
    },
    {
        field: 'JrnlMemo',
        title: 'Memo',
        hozAlign: 'left',
        formatter: formatters.tabulatorFormaterJrnlMemo,
        headerFilter: 'input',
        width: 230
    },
    {
        field: 'U_PHO_Type',
        title: 'Type',
        hozAlign: 'left',
        width: 130,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'U_PHO_Type' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'U_PHO_Type' }
    },
    {
        field: 'ObjectType',
        title: 'Doc Type',
        hozAlign: 'left',
        width: 130,
        formatter: formatters.tabulatorFormatterDocType,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'ObjectType' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'ObjectType' }
    },
    {
        field: 'DocNum',
        title: 'Doc Num',
        hozAlign: 'left',
        vertAlign: 'middle',
        width: 100,
        headerFilter: 'input',
        formatter: formatters.tabulatorFormatterSapDocNum
    },
    {
        field: 'DocMessage',
        title: 'Message',
        formatter: formatters.tabulatorFormatterDocMessage,
        width: 100,
        headerFilter: 'input'
    },
    {
        field: 'DocDueDate',
        title: 'In/<span class="datePast">Late</span> <span style="float:right">Due Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: {
            suppressFormating: (_value: any, rowData: any): boolean =>
                rowData.DocStatus === 'C' || rowData.DocStatusProductionOrder === 'C' || rowData.DocStatusProductionOrder === 'L',
            showAge: true,
            highlightPastStyle: 'datePast',
            highlightDateConfirmedStyle: 'dateConfirmed'
        }
    },
    {
        field: 'ReqDate',
        title: 'In/<span class="datePast">Late</span> <span style="float:right">Req Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: {
            suppressFormating: (_value: any, rowData: any): boolean =>
                rowData.DocStatus === 'C' || rowData.DocStatusProductionOrder === 'C' || rowData.DocStatusProductionOrder === 'L',
            showAge: true,
            highlightPastStyle: 'datePast'
        }
    },
    {
        field: 'DocDate',
        title: 'age<span style="float:right">Doc Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        field: 'CreateDate',
        title: 'age<span style="float:right">Create Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        field: 'UpdateDate',
        title: 'age<span style="float:right">Update Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        field: 'CloseDate',
        title: 'age<span style="float:right">Doc Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        field: 'RemovedDate',
        title: 'age<span style="float:right">Removed Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        field: 'DocUpdateDate',
        title: 'age<span style="float:right">Update Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        field: 'ShipDate',
        title: 'age<span style="float:right">Ship Date</span>',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        field: 'Project',
        title: 'Project',
        width: 100,
        headerFilter: 'input'
    },
    {
        field: 'NumAtCard',
        title: 'NumAtCard',
        width: 100,
        headerFilter: 'input'
    },
    {
        field: 'ProgressPercentPositive',
        title: 'Progress',
        width: 60,
        formatter: formatters.tabulatorFormatterProgressEx,
        formatterParams: percentFormatterParamsPositive
    },
    {
        field: 'OpenOrdersList',
        title: 'On Orders (opened)',
        hozAlign: 'right',
        width: 150,
        variableHeight: true,
        formatter: 'html'
    },
    {
        field: 'PlannedQty',
        title: 'Planned',
        sorter: 'number',
        width: 80,
        hozAlign: 'right'
    },
    {
        field: 'CmpltQty',
        title: 'Completed',
        sorter: 'number',
        width: 80,
        hozAlign: 'right'
    },
    {
        field: 'ComponentsManualIssuedPercentPositive',
        title: 'Manualy Issued',
        width: 90,
        formatter: formatters.tabulatorFormatterProgressEx,
        formatterParams: percentFormatterParamsPositive
    },
    {
        field: 'CmpManIssueTotal',
        title: 'Man Iss. Cmp Total',
        sorter: 'number',
        width: 80,
        hozAlign: 'right'
    },
    {
        field: 'CmpManIssued',
        title: 'Man Iss. Cmp',
        width: 90,
        sorter: 'number',
        hozAlign: 'right'
    },
    {
        field: 'IsCommited',
        title: 'IsCommited',
        sorter: 'number',
        hozAlign: 'right',
        width: 80,
        formatter: formatters.tabulatorformatterQuantity,
        formatterParams: { showZeroAsBlank: true, negativeHighlightClass: ['bg-danger', 'text-white'] }
    },
    {
        field: 'templateQuantity',
        title: 'templateQuantity',
        sorter: 'number',
        hozAlign: 'right',
        vertAlign: 'middle',
        width: 65,
        formatter: formatters.tabulatorformatterQuantity,
        formatterParams: { showZeroAsBlank: true, negativeHighlightClass: ['bg-danger', 'text-white'] }
    },
    {
        field: 'OnHand',
        title: 'OnHand',
        sorter: 'number',
        hozAlign: 'right',
        width: 80,
        formatter: formatters.tabulatorformatterQuantity,
        formatterParams: { showZeroAsBlank: true, negativeHighlightClass: ['bg-danger', 'text-white'] }
    },
    {
        field: 'OnOrder',
        title: 'OnOrder',
        sorter: 'number',
        hozAlign: 'right',
        width: 80,
        formatter: formatters.tabulatorformatterQuantity,
        formatterParams: { showZeroAsBlank: true, negativeHighlightClass: ['bg-danger', 'text-white'] }
    },
    {
        field: 'Available',
        title: 'Available',
        sorter: 'number',
        hozAlign: 'right',
        width: 80,
        formatter: formatters.tabulatorformatterQuantity,
        formatterParams: {
            negativeHighlightClass: ['bg-danger', 'text-white'],
            positiveHighlightClass: ['bg-success', 'text-white'],
            warningHighlightClass: ['bg-warning', 'text-white']
        }
    },
    {
        field: 'DocTotal',
        title: 'DocTotal',
        sorter: 'number',
        hozAlign: 'right',
        width: 80,
        formatter: 'money',
        formatterParams: { thousand: ' ', symbol: '' }
    },
    {
        field: 'DocCur',
        title: 'DocCur',
        sorter: 'string',
        hozAlign: 'left',
        width: 48
    },
    {
        field: 'Quantity',
        title: 'Quantity',
        sorter: 'number',
        hozAlign: 'right',
        vertAlign: 'middle',
        width: 80,
        formatter: formatters.tabulatorformatterQuantity,
        formatterParams: { showZeroAsBlank: true, negativeHighlightClass: ['bg-danger', 'text-white'] }
    },
    {
        field: 'Lines',
        title: 'Lines Total',
        sorter: 'number',
        width: 50,
        hozAlign: 'right'
    },
    {
        field: 'LinesClosed',
        title: 'Lines Closed',
        sorter: 'number',
        width: 50,
        hozAlign: 'right'
    },
    {
        field: 'SparePartsQty',
        title: 'Spare Parts Qty',
        sorter: 'number',
        hozAlign: 'right',
        width: 80
    },
    {
        field: 'ScrapPartsQty',
        title: 'Scrap Qty',
        sorter: 'number',
        hozAlign: 'right',
        width: 80
    },
    {
        field: 'QuarantinedPartsQty',
        title: 'Quarantined Qty',
        sorter: 'number',
        hozAlign: 'right',
        width: 80
    },
    {
        field: 'DraftDocOwner',
        title: 'Draft Owner',
        width: 140,
        hozAlign: 'center',
        formatter: formatters.tabulatorformatterSapEmployeeObject
    },
    {
        field: 'DocCreatorName',
        title: 'Creator',
        width: 140,
        hozAlign: 'center',
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'DocCreatorName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'DocCreatorName' }
    },
    {
        field: 'DocOwnerName',
        title: 'Owner',
        width: 140,
        hozAlign: 'center',
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'DocOwnerName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'DocOwnerName' }
    },
    {
        field: 'DocPurchaserName',
        title: 'Purchaser',
        width: 140,
        hozAlign: 'center',
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'DocPurchaserName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'DocPurchaserName' }
    },
    {
        field: 'DocRequesterName',
        title: 'Requester',
        width: 140,
        hozAlign: 'center',
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'DocRequesterName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'DocRequesterName' }
    },
    {
        field: 'DocSalesName',
        title: 'Sales',
        width: 140,
        hozAlign: 'center',
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'DocOwnerName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'DocOwnerName' }
    },
    {
        field: 'Comments',
        title: 'Comments',
        headerFilter: 'input',
        width: 250
    },
    {
        field: 'FirmCode',
        title: 'Firm Code',
        width: 150,
        headerFilter: 'input'
    },
    {
        field: 'FirmName',
        title: 'Firm Name',
        width: 150,
        headerFilter: 'input',
        vertAlign: 'middle'
    },
    {
        field: 'SuppCatNum',
        title: 'Supplier SKU',
        width: 120,
        headerFilter: 'input',
        vertAlign: 'middle'
    },
    {
        field: 'MPN',
        title: 'MPN',
        width: 120,
        vertAlign: 'middle',
        headerFilter: 'input'
    },
    {
        field: 'PayBlock',
        title: 'PayBlock',
        width: 120,
        headerFilter: 'input'
    },
    {
        field: 'PeyMethod',
        title: 'PeyMethod',
        width: 120,
        headerFilter: 'input'
    },
    {
        field: 'TreeType',
        title: 'BOM Type',
        hozAlign: 'center',
        width: 75,
        formatter: formatters.tabulatorFormatterTreeType,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'TreeType' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'TreeType' }
    },
    {
        field: 'BOMs',
        title: 'BOMs',
        hozAlign: 'center',
        width: 75,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'BOMs' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'BOMs' }
    },
    {
        field: 'CSBusinessUnitName',
        title: 'BU',
        hozAlign: 'center',
        width: 80,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'CSBusinessUnitName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'CSBusinessUnitName ' }
    },
    {
        field: 'CSCostCenterName',
        title: 'CC',
        hozAlign: 'center',
        minWidth: 120,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'CSCostCenterName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'CSCostCenterName' }
    },
    {
        field: 'CSBudgetName',
        title: 'Budget',
        hozAlign: 'center',
        minWidth: 160,
        headerFilterLiveFilter: false,
        headerFilter: helpers.multiSelectHeaderFilter,
        headerFilterParams: { path: 'CSBudgetName' },
        headerFilterFunc: helpers.multiSelectHeaderFilterFunction,
        headerFilterFuncParams: { path: 'CSBudgetName' }
    },
    {
        field: 'CSKeysName',
        title: 'Keys',
        hozAlign: 'center',
        headerFilter: 'input',
        minWidth: 80,
        visible: false
    },
    {
        field: 'U_PHO_POSubj',
        title: 'Subject',
        hozAlign: 'left',
        headerFilter: 'input',
        width: 300,
        formatterParams: {}
    },
    {
        field: 'U_PHO_LINK',
        title: 'Link',
        hozAlign: 'center',
        headerFilter: 'input',
        width: 80,
        formatter: formatters.tabulatorformatterLinkAdvanced,
        formatterParams: {}
    },
    {
        field: 'AssetNo',
        title: 'AssetNo',
        headerFilter: 'input',
        width: 120
    },
    {
        field: 'AssetDescription',
        title: 'Description',
        headerFilter: 'input',
        width: 220
    },
    {
        field: 'SerialNo',
        title: 'SerialNo',
        headerFilter: 'input',
        width: 150
    },
    {
        field: 'ProjectCode',
        title: 'ProjectCode',
        headerFilter: 'input',
        width: 100
    },
    {
        field: 'projectCodeNormalized',
        title: 'ProjectCode',
        headerFilter: 'input',
        hozAlign: 'left',
        formatter: (cell: TabulatorTables.Tabulator.CellComponent) => {
            const root = ReactDOM.createRoot(cell.getElement());
            root.render(
                <>
                    <span className="font-monospace">{projectCodeNormalizedToOriginal(cell.getValue())}</span>
                </>
            );
            return null;
        },
        width: 70
    },
    {
        field: 'ApprovalRequestCode',
        title: 'Code',
        headerFilter: 'input',
        width: 90
    },
    {
        field: 'LastPurPrc',
        title: 'Last Purchase Price',
        headerFilter: 'input',
        width: 90,
        hozAlign: 'right',
        formatter: 'money',
        formatterParams: {
            symbol: '€ ',
            thousand: ' ',
            negativeSign: true
        }
    },
    {
        field: 'AvgPrice',
        title: 'Avg Price',
        headerFilter: 'input',
        width: 90,
        hozAlign: 'right',
        formatter: 'money',
        formatterParams: {
            symbol: '€ ',
            thousand: ' ',
            negativeSign: true
        }
    },
    {
        field: 'DownpaymentPercentPaid',
        title: 'Down Payment % Paid',
        headerFilter: 'input',
        width: 90,
        hozAlign: 'right',
        formatter: formatters.tabulatorFormatterProgressEx,
        formatterParams: percentFormatterParamsPositive
    },
    {
        field: 'DownPayment',
        title: 'Down Payment',
        headerFilter: 'input',
        width: 90,
        hozAlign: 'right',
        formatter: 'money',
        formatterParams: {
            symbol: '',
            thousand: ' ',
            negativeSign: true
        }
    },

    {
        field: 'DownPaymentPaid',
        title: 'DP Paid',
        headerFilter: 'input',
        width: 90,
        hozAlign: 'right',
        formatter: 'money',
        formatterParams: {
            symbol: '',
            thousand: ' ',
            negativeSign: true
        }
    },

    {
        field: 'DownPaymentRest',
        title: 'DP Rest',
        headerFilter: 'input',
        width: 90,
        hozAlign: 'right',
        formatter: 'money',
        formatterParams: {
            symbol: '',
            thousand: ' ',
            negativeSign: true
        }
    },
    {
        field: 'U_PHO_DeliveryStat',
        title: 'Delivery Status',
        headerFilter: 'input',
        width: 90,
        hozAlign: 'center'
    },
    {
        field: 'U_PHO_TRNO',
        title: 'Track NO',
        headerFilter: 'input',
        width: 90
    },

    /* -------------------

        JIRA

    */

    {
        title: 'Status',
        field: 'status.name',
        width: 90,
        hozAlign: 'center',
        sorter: 'string',
        formatter: formatters.tabulatorFormatterJiraStatus,
        topCalc: helpers.keyCounts,
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'Summary',
        field: 'summary',
        width: 300,
        sorter: 'string',
        formatter: formatters.tabulatorformatterValueWithExternalLink,
        formatterParams: { urlPrefix: jiraHelpers.jiraURLPrefix, urlField: 'key' }
    },
    {
        title: 'Code',
        field: 'projectCode.value',
        width: 100,
        sorter: 'string',
        hozAlign: 'center'
    },
    {
        title: 'Assignee',
        field: 'assignee',
        width: 140,
        sorter: 'string',
        formatter: formatters.tabulatorformatterJiraUserWithAvatar,
        topCalc: helpers.keyCounts,
        topCalcParams: { field: 'displayName' },
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'Reporter',
        field: 'reporter',
        width: 140,
        sorter: 'string',
        formatter: formatters.tabulatorformatterJiraUserWithAvatar,
        topCalc: helpers.keyCounts,
        topCalcParams: { field: 'displayName' },
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'HQ PM',
        field: 'HQPM',
        width: 140,
        sorter: 'string',
        formatter: formatters.tabulatorformatterJiraUserWithAvatar,
        topCalc: helpers.keyCounts,
        topCalcParams: { field: 'displayName' },
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'Sales',
        field: 'salesPerson',
        width: 140,
        sorter: 'string',
        formatter: formatters.tabulatorformatterJiraUserWithAvatar,
        topCalc: helpers.keyCounts,
        topCalcParams: { field: 'displayName' },
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'Sales Engineer',
        field: 'salesEngineer',
        width: 140,
        sorter: 'string',
        formatter: formatters.tabulatorformatterJiraUserWithAvatar,
        topCalc: helpers.keyCounts,
        topCalcParams: { field: 'displayName' },
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'Category',
        field: 'projectCategory.value',
        width: 100,
        sorter: 'string',
        hozAlign: 'center',
        topCalc: helpers.keyCounts,
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'Contract Type',
        field: 'contractType.value',
        width: 100,
        sorter: 'string',
        hozAlign: 'center',
        topCalc: helpers.keyCounts,
        topCalcFormatter: formatters.tabulatorformatterKeyCountHeader,
        topCalcFormatterParams: { separator: '&nbsp;' }
    },
    {
        title: 'Network',
        field: 'networkAddress',
        width: 100,
        sorter: 'string',
        hozAlign: 'center'
    },
    {
        title: 'Production Start',
        field: 'productionStartDate',
        width: 100,
        sorter: 'string'
    },
    {
        title: 'City',
        field: 'city',
        headerFilter: 'input',
        width: 100,
        sorter: 'string'
    },
    {
        title: 'Customer',
        field: 'customer.value',
        width: 200,
        sorter: 'string'
    },
    {
        title: 'Intuition version',
        field: 'intuitionVersion',
        width: 100,
        sorter: 'string'
    },
    {
        title: 'Intuition deployed',
        field: 'intuitionLastUpdate',
        hozAlign: 'right',
        width: 115,
        formatter: formatters.formatterDateExtended,
        formatterParams: { showAge: true }
    },
    {
        title: 'Operation & Checkin',
        field: 'operationalInfo',
        width: 120,
        sorter: 'string',
        visible: true,
        formatter: (cell: TabulatorTables.Tabulator.CellComponent, params: any, onRendered: () => void) => {
            const project = cell.getData() as JiraBrightpickProject;
            return reactFormatterEx(
                <div className="d-flex h-100 gap-2 align-items-center justify-content-between">
                    <IntuitionLogicStateChip state={project.operationalInfo?.logicState} />
                    <Avatar {...project.operationalInfo?.checkin?.onsite} tooltipAdditional="as onsite" showUnknownIfNull />
                    <Avatar {...project.operationalInfo?.checkin?.remote} tooltipAdditional="as remote" />
                    {/* <ProjectOperationalStatusElement operationalInfo={project.operationalInfo} />  */}
                </div>
            )(cell, params, onRendered);
        }
    },
    {
        title: 'Links',
        field: 'projectLinks',
        width: 240,
        sorter: 'string',
        visible: true,
        formatter: (cell: TabulatorTables.Tabulator.CellComponent, params: any, onRendered: () => void) => {
            const project = cell.getData() as JiraBrightpickProject;
            return reactFormatterEx(
                <>
                    <ProjectCardFooter project={project} addClassMaster="hover_show" />
                </>
            )(cell, params, onRendered);
        }
    },
    {
        title: 'Status',
        field: 'currentOperationalStatus',
        width: 50,
        sorter: function (a: ProjectSemaphore, b: ProjectSemaphore) {
            if (a?.value == null || b?.value == null) return -1;
            const getMerits = (status: ProjectSemaphore | null) => {
                switch (status?.value) {
                    case 'GREEN':
                        return 3;
                    case 'YELLOW':
                        return 2;
                    case 'RED':
                        return 1;
                    case 'BLACK':
                        return 0;
                    default:
                        return 0;
                }
            };

            return getMerits(a) - getMerits(b);
        },
        hozAlign: 'right',
        visible: true,
        formatter: (cell: TabulatorTables.Tabulator.CellComponent, params: any, onRendered: () => void) => {
            const project = cell.getData() as JiraBrightpickProject;
            return reactFormatterEx(
                <>
                    <ProjectSemaphoreChip value={project.currentOperationalStatus} />
                </>
            )(cell, params, onRendered);
        }
    },
    {
        title: 'Performance',
        field: 'recentPerformanceValues',
        width: 70,
        hozAlign: 'center',
        visible: true,
        formatter: (cell: TabulatorTables.Tabulator.CellComponent, params: any, onRendered: () => void) => {
            const project = cell.getData() as JiraBrightpickProject;
            return reactFormatterEx(
                <>
                    <div style={{ position: 'relative', height: '30px', display: 'inline-block' }}>
                        <ProjectPerformanceSparkline projectCodeNormalized={project.projectCodeNormalized} />
                    </div>
                </>
            )(cell, params, onRendered);
        }
    }
];
