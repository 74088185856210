import React from 'react';
import { useAxiosEx } from '../DataLoader';
import { AuthenticatedUser } from '@bp/utils';

const url = `/api-jira/frontendinfo`;

export function useFrontendInfo(user: AuthenticatedUser | null): any {
    const [{ data, loading, error }, refetch] = useAxiosEx(url);

    React.useEffect(() => {
        void refetch();
    }, [user?.g_access_token]);

    return [data, loading, error, refetch];
}
