import { Badge, Button } from 'react-bootstrap';
import { useShipmentTracking } from '../../Hooks/useShipmentTracking';
import { useInterval } from '../../Hooks/useInterval';
import React from 'react';
import { DateTime } from 'luxon';

interface TrackingProps {
    trackingId: string | null;
    trackingCompany: string | null;
    autoRefreshMinutes?: number;
}

export function ShipmentTrackerChip(props: TrackingProps) {
    const [{ data: shipment, loading, error }, refresh] = useShipmentTracking(props.trackingCompany, props.trackingId);

    console.log('shipment', props, shipment, error);
    useInterval(
        () => {
            refresh();
        },
        1000 * 60 * (props.autoRefreshMinutes ?? 1000)
    );

    /*
    const status = React.useMemo(() => {
        if (error != null) return { lastEvent: undefined, isDelivered: undefined, variant: 'danger' };
        if (!shipment) return { lastEvent: undefined, isDelivered: undefined, variant: 'secondary' };

        const lastEvent = shipment.events.length > 0 ? shipment.events[shipment.events.length - 1] : undefined;
        const isDelivered = shipment.events.some((event) => event.typeCode === 'OK');
        const variant = error != null ? 'danger' : isDelivered ? 'success' : 'primary';
        const eta = DateTime.fromISO(shipment.estimatedDeliveryDate);

        return { lastEvent, isDelivered, variant, eta: isDelivered ? undefined : '- ETA: ' + eta.toRelative() };
    }, [shipment, error]);
    */

    const variant = error != null ? 'danger' : shipment?.status === 'DELIVERED' ? 'success' : 'primary';

    return (
        <Badge
            as={Button}
            bg={variant}
            onClick={() => {
                window.open(shipment?.trackingLink ?? '', '_blank');
            }}
        >
            {shipment?.carrierName} {shipment?.trackingNumber} - {loading ? 'loading ...' : `${shipment?.status}`}
        </Badge>
    );
}
