import { useAxiosEx } from '../DataLoader';

export enum ShipmentStatus {
    Open = 'OPEN',
    InTransit = 'IN_TRANSIT',
    Exception = 'EXCEPTION',
    OutForDelivery = 'OUT_FOR_DELIVERY',
    Delivered = 'DELIVERED',
    PartiallyDelivered = 'PARTIALLY_DELIVERED',
    Unknown = 'UNKNOWN'
}

export type ShipmentCarrier = 'DHL' | 'GEBRUDER_WEISS';

export type ShipmentInfo = {
    status: ShipmentStatus;
    carrierCode: ShipmentCarrier;
    carrierName: string;
    trackingLink: string | null;
    trackingNumber: string;
    piecesTotal: number | null;
    lastLocation: string | null;
    lastUpdate: Date | null;
    carrierInfo: object | null;
};

export function useShipmentTracking(carrier: string | null, trackingId: string | null) {
    const url = `/api-proxy/track-shipment?carrier=${carrier}&trackingNumber=${trackingId}`;
    return useAxiosEx<ShipmentInfo>(url, { manual: trackingId == null || carrier == null });
}
