export const JIRA_BASE_URL = process.env.REACT_APP_JIRA_BASE_URL;
export const jiraURLPrefix = JIRA_BASE_URL + '/browse/';

export function GetJiraExternalLinkSpan(jiraKey: string): string {
    return `<a href="${jiraURLPrefix}${jiraKey}" target="_blank"><i style="float:right; margin-right: 4px;" class="bi bi-box-arrow-up-right"></i></a>`;
}

export function GetJiraStatusClass(status: string): string {
    let type = '';
    switch (status) {
        case 'Delivered':
        case 'Done':
        case 'Canceled':
        case 'Production':
            type = 'statusBuble_Green';
            break;
        case 'Internal':
        case 'Assembly':
        case 'Built':
            type = 'statusBuble_Blue';
            break;
        case 'Deployment':
        case 'In Progress':
            status = 'Progress';
            type = 'statusBuble_Blue';
            break;
        case 'New':
        case 'On Stock':
        case 'Calibrated':
        case 'Requested from stock':
        case 'Maintenance':
            type = 'statusBuble_Grey';
            break;
        case 'Planned':
            type = 'statusBuble_Grey';
            status = 'Plan';
            break;
        case 'Fix at place':
        case 'Fix needed':
        case 'On Hold':
        case 'Blocker':
        case 'Closing':
            type = 'statusBuble_Red';
            break;
        case 'Backlog':
            type = 'statusBuble_Orange';
            break;
        case 'EOL':
            type = 'statusBuble_Grey';
            break;
        case 'R&D':
            type = 'statusBuble_Blue';
            break;
        case 'L3 Support':
            type = 'statusBuble_Orange';
            break;
        case 'Support':
            type = 'statusBuble_Blue';
            break;
        case 'Ready For Resolution':
            // status = "Ready"
            type = 'statusBuble_Green';
            break;

        default:
            type = 'statusBuble_Grey';
    }
    return type;
}

export function GetJiraStatusSpan(
    status: any,
    value: string | undefined = undefined,
    title: string | undefined = undefined,
    showDetail: boolean | undefined = undefined
): string {
    if (!status) return '';
    const type = GetJiraStatusClass(status);

    switch (status) {
        case 'In Progress':
            status = 'Progress';
            break;
        case 'Planned':
            status = 'Plan';
            break;
    }

    if (value === undefined) value = status;
    let detailedText = '';
    if (showDetail !== undefined && showDetail) detailedText = title + ': ';
    return '<span title="' + title + '" class="statusBuble ' + type + '">' + detailedText + value + '</span>';
}

export function GetJiraAvatarSpan(obj: any): string {
    if (!obj?.name || !obj.avatar) return '';
    return `<img style="border-radius: 50%; vertical-align: top" height="21px" width="21px" title="${obj.name as string}" src="${obj.avatar as string}">`;
}

export function GetProductsSpan(products: string[]): string {
    let type = '';
    let html = '';
    products.forEach((p) => {
        switch (p) {
            case 'AMR':
                type = 'statusBuble_Purple';
                break;
            case 'Addon-Lift':
                type = 'statusBuble_Aqua';
                p = 'ADDON';
                break;
            default:
                type = 'statusBuble_Grey';
        }
        html += '<span title="' + p + '" class="' + type + '">' + p + '</span>';
    });
    return html;
}

export function GetLinkSpan(text: string, url: string): string {
    let html = '';
    html += "<a href='" + url + "'>" + text + '</a>';
    return html;
}
