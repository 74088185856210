import React, { useState } from 'react';

interface WithCopyProps {
    /** The text that will be copied to the clipboard */
    textToCopy: string;
}

export const withCopyToClipboardHoverIcon = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const ComponentWithCopy: React.FC<P & WithCopyProps> = ({ textToCopy, ...props }) => {
        const [isHovered, setIsHovered] = useState(false);
        const [copied, setCopied] = useState(false);

        const handleCopy = async () => {
            try {
                await navigator.clipboard.writeText(textToCopy);
                setCopied(true);
                // Reset the copied state after 2 seconds
                setTimeout(() => setCopied(false), 2000);
            } catch (error) {
                console.error('Copy failed:', error);
            }
        };

        return (
            <div
                style={{ position: 'relative', display: 'inline-block', paddingRight: '24px' }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <WrappedComponent {...(props as P)} />
                {isHovered && (
                    <span
                        onClick={handleCopy}
                        title="Copy to clipboard"
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '4px', // Position the icon within the padded area
                            top: '40%', // Center the icon vertically
                            transform: 'translateY(-50%)'
                        }}
                    >
                        {copied ? (
                            // Green check icon when copied
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        ) : (
                            // Copy icon (using a simple clipboard icon)
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" className="bi bi-copy" viewBox="0 0 16 16">
                                <path
                                    fillRule="evenodd"
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                            </svg>
                        )}
                    </span>
                )}
            </div>
        );
    };

    return ComponentWithCopy;
};
